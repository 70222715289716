
































































































































































































































































import { Prop, Component, Vue, Watch } from 'vue-property-decorator'
import UserService from '@/services/UserService'
import moment from 'moment'
import momentTimeZone from 'moment-timezone'
import FormatDate from '@/helpers/FormatDate'
import GroupChatService from '@/services/GroupChatService'
import linkifyHtml from "linkify-html";

@Component
export default class ModalShowProfile extends Vue {
  @Prop({ default: 'modal-show-profile' }) id!: string
  @Prop() userId!: number
  private user_info: any = {}
  private user_personal_info: any = {}
  private avatar_default: any = require('@/assets/images/avatar-default.png')
  private current_date: any = ''
  private last_login_at: any = ''
  private to_be_logged_out: any = ''
  private diff_login: number = 0
  private diff_hours: number = 0
  private diff_day: number = 0
  private date_of_birth: any = ''
  private current_user: number = this.$store.state.userInfo.user.info.id
  private isShow: boolean = false

  @Watch('userId')
  getUserProfile() {
    if (this.userId !== -1) {
      this.getUserProfileById()
      this.getUserPersonalInfoById()
    } else {
      this.user_info = {}
      this.user_personal_info = {}
    }
  }

  handleShow() {
    this.isShow = true
    setTimeout(() => (this.isShow = false), 500)
  }

  getUserPersonalInfoById() {
    UserService.getUserPersonalInfoById(this.userId).then(response => {
      if (response.status === 200) {
        this.user_personal_info = response.data
        if (
          this.user_personal_info.birthday_year &&
          this.user_personal_info.birthday_month &&
          this.user_personal_info.birthday_day
        ) {
          this.date_of_birth =
            this.user_personal_info.birthday_year +
            '/' +
            this.user_personal_info.birthday_month +
            '/' +
            this.user_personal_info.birthday_day
        } else {
          this.date_of_birth = ''
        }
      }
    })
  }

  formatDateWithoutDays(date: string) {
    if (date !== '') {
      return FormatDate.formatDateWithoutDays(date)
    } else return null
  }

  getUserProfileById() {
    UserService.getUserProfileById(this.userId)
      .then(response => {
        if (response.status === 200) {
          this.user_info = response.data
          this.to_be_logged_out = response.data.to_be_logged_out
          this.current_date = momentTimeZone
            .tz('Asia/Tokyo')
            .format('YYYY/MM/DD HH:mm:ss')
          this.last_login_at = moment(response.data.last_login_at).format(
            'YYYY/MM/DD HH:mm:ss'
          )
          this.diff_login = moment(this.current_date).diff(
            this.last_login_at,
            'days'
          )
          this.diff_hours = moment(this.current_date).diff(
            this.last_login_at,
            'hours'
          )
          let current_date_without_hours = momentTimeZone
            .tz('Asia/Tokyo')
            .format('YYYY/MM/DD')
          let last_login_without_hours = moment(
            response.data.last_login_at
          ).format('YYYY/MM/DD')
          this.diff_day = moment(current_date_without_hours).diff(
            last_login_without_hours,
            'days'
          )
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  handleLoginStatus() {
    //online
    if (
      !this.diff_login &&
      !this.diff_day &&
      this.diff_hours === 0 &&
      !this.to_be_logged_out
    )
      return this.$t('modal_show_profile.online')
    //hours_ago
    else if (!this.diff_login && !this.diff_day && this.diff_hours > 0)
      return this.$t('modal_show_profile.hours_ago', { hours: this.diff_hours })
    //less_than_seven diff_day
    else if (!this.diff_login && this.diff_day && this.diff_hours < 24)
      return this.$t('modal_show_profile.less_than_seven', {
        day: this.diff_day
      })
    //less_than_seven diff_login
    else if (this.diff_login > 0 && this.diff_login <= 7)
      return this.$t('modal_show_profile.less_than_seven', {
        day: this.diff_login
      })
    //more_than_seven
    else if (this.diff_login > 7)
      return this.$t('modal_show_profile.more_than_seven')
    //not_login
    else if (!this.diff_login) {
      return this.diff_hours == 0
        ? this.$t('modal_show_profile.logged_out')
        : this.$t('modal_show_profile.not_login')
    }
  }

  hideModal() {
    this.$bvModal.hide(this.id)
  }

  /**
   * Check create group chat 2 member
   * Chatted in history ? comeback old group : create new group chat
   */
  checkGroupChatExist(user_id: number) {
    GroupChatService.getGroupChatExist(user_id)
      .then(res => {
        if (res.status === 200) {
          if (res.data.is_existed === true) {
            let group_chat_id = res.data.group_chat.id
            GroupChatService.revertOriginMember(group_chat_id).then(res => {
              if (res.status === 200) {
                this.$router.push({
                  name: 'chat-detail',
                  params: { groupChatId: group_chat_id }
                })
                this.$bvModal.hide('modal-show-profile')
              }
            }).catch(err => console.log(err))
          } else {
            this.createNewGroupChat()
          }
        }
      })
      .catch(err => console.log(err))
  }

  /**
   * Create new group chat witdh user in modal show profile
   */
  async createNewGroupChat() {
    let data = {
      user_ids: this.userId.toString(),
      sender_id: this.$store.state.userInfo.user.profile.user_id,
      last_message: '',
      size: 0,
      last_message_special: false,
      only_user: true,
      name: this.user_info.name + this.$t('common.suffix.san'),
      group_id: this.$route.params.groupId
    }
    const res = await GroupChatService.createNewGroupChat(data)
    if (res.status === 201) {
      await GroupChatService.revertOriginMember(res.data.id)
      this.$router.push({
        name: 'chat-detail',
        params: { groupChatId: `${res.data.id}` }
      })
    }
  }

  handleLinkifyContent(content: string) {
    return linkifyHtml(content || '', { target: "_blank" });
  }
}
